import React from "react";

function Button({ type, name, onClick, disabled, icons }) {
  return (
    <div className="flex items-center justify-center mt-4">
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={`bg-[#3c7bc4] hover:bg-[#36454F] text-[#ffffff] hover:text-[#ffffff] font-bold py-2 px-4 rounded  focus:ring-2 focus:outline-none focus:ring-opacity-70 ring-[#36454F] font-mono flex items-center justify-between w-full space-x-4  max-md:text-xs ${
          disabled ? "cursor-not-allowed" : null
        }`}
      >
        {name}{" "}
        <span className="lg:hidden xl:block sm:block">{icons && icons}</span>
      </button>
    </div>
  );
}

export default Button;
