import React from "react";
import { FaTimes } from "react-icons/fa";
import {
  MdEmail,
  MdPhone,
  MdSchool,
  MdDateRange,
  MdPerson,
  MdLabel,
  MdTimeline,
  MdAccountCircle,
} from "react-icons/md";
import { FaCodeBranch } from "react-icons/fa6";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { RiPagesFill } from "react-icons/ri";
import { FaUserCheck } from "react-icons/fa";
import { BsPinMapFill } from "react-icons/bs";

function UserDetails({ selectedUser, onClose }) {
  return (
    <div
      onClick={onClose}
      className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-[#EEEDEB] mx-4  w-full max-w-3xl sm:p-10 p-4 rounded-lg shadow-lg relative text-sm"
      >
        <button
          className="absolute sm:top-2 top-5 sm:right-2 right-4 text-gray-700  max-sm:text-[#FFFAFA] hover:text-gray-900 hover:bg-gray-200 rounded-full p-1"
          onClick={onClose}
        >
          <FaTimes size={24} />
        </button>
        <div className="text-center bg-[#36454F] rounded-t-xl p-4">
          <h2 className="text-3xl font-semibold text-[#FFFFFF]">User Detail</h2>
        </div>
        <div className="w-full overflow-auto  max-h-[70vh] bg-[#EEEDEB] border border-[#1f1d49] p-4">
          <div className="sm:p-4" onClick={(e) => e.stopPropagation()}>
            <div className="p-4 grid grid-cols-1   w-full mb-2">
              <div className="flex items-center mb-2 justify-end flex-wrap">
                <FaUserCheck className="mr-3 text-gray-600" size={28} />
                <span className="font-medium pr-2">Username : </span>{" "}
                {selectedUser?.username}
              </div>
              <div className="flex items-center mb-2 justify-end flex-wrap">
                <RiPagesFill className="mr-3 text-gray-600" size={28} />
                <span className="font-medium pr-2">Status : </span>{" "}
                {selectedUser?.status === "pending" ? (
                  <span className="text-xl text-orange-400">
                    {selectedUser?.status}
                  </span>
                ) : selectedUser?.status === "Approved" ? (
                  <span className="text-xl text-green-500 font-semibold">
                    {selectedUser?.status}
                  </span>
                ) : (
                  <span className="text-xl text-red-400">
                    {selectedUser?.status}
                  </span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="bg-white shadow-2xl rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-4">Contact Details:</h3>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdPerson className="mr-3 text-green-600" />
                  <span className="font-medium pr-2">Name:</span>{" "}
                  {selectedUser?.student_name}
                </div>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdEmail className="mr-3 text-blue-600" />
                  <span className="font-medium pr-2">Email:</span>{" "}
                  {selectedUser?.email}
                </div>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdPhone className="mr-3 text-indigo-600" />
                  <span className="font-medium pr-2">Mobile Number:</span>{" "}
                  {selectedUser?.mobile_number}
                </div>
              </div>

              <div className="bg-white shadow-xl rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-4">
                  Internship Details:
                </h3>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdTimeline className="mr-3 text-pink-600" />
                  <span className="font-medium pr-2">
                    Internship Type:
                  </span>{" "}
                  {selectedUser?.internship_type}
                </div>
                <div className="flex items-center mb-2 flex-wrap">
                  <BsPinMapFill className="mr-3 text-pink-600" />
                  <span className="font-medium pr-2">
                    Area Of Internship:
                  </span>{" "}
                  {selectedUser?.internship_area}
                </div>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdDateRange className="mr-3 text-teal-600" />
                  <span className="font-medium pr-2">Start Date:</span>{" "}
                  {selectedUser?.internship_start_date}
                </div>
                <div className="flex items-cente flex-wrapr">
                  <MdDateRange className="mr-3 text-teal-600" />
                  <span className="font-medium pr-2">End Date:</span>{" "}
                  {selectedUser?.internship_end_date}
                </div>
              </div>
              <div className="bg-white shadow-xl rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-4">College Details:</h3>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdAccountCircle className="mr-3 text-red-600" />
                  <span className="font-medium pr-2">College:</span>{" "}
                  {selectedUser?.college_name}
                </div>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdSchool className="mr-3 text-purple-600" />
                  <span className="font-medium pr-2">Course:</span>{" "}
                  {selectedUser?.course}
                </div>
                <div className="flex items-center mb-2 flex-wrap">
                  <FaCodeBranch className="mr-3 text-yellow-600" />
                  <span className="font-medium pr-2">Branch Name:</span>{" "}
                  <span className="whitespace-pre-wrap text-sm">
                    {selectedUser?.branch}
                  </span>
                </div>
                <div className="flex items-center mb-2 flex-wrap">
                  <MdLabel className="mr-3 text-yellow-600" />
                  <span className="font-medium pr-2">Semester:</span>{" "}
                  {selectedUser?.semester}
                </div>
                <div className="flex items-center flex-wrap">
                  <AiOutlineFieldNumber className="mr-3 text-yellow-600" />
                  <span className="font-medium pr-2">
                    Enrollment Number:
                  </span>{" "}
                  {selectedUser?.enrollment_number}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
