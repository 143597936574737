import React from "react";

function Approve({ type, disabled, onClick, name }) {
  return (
    <div className="flex items-center justify-center mt-4">
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={`bg-[#D8EFD3] hover:bg-[#508D4E] text-green-400 hover:text-[#ffffff] font-bold py-2 px-4 rounded  focus:ring-2 focus:outline-none focus:ring-opacity-70 ring-[#4cbc96] font-mono ${
          disabled ? "cursor-not-allowed" : null
        }`}
      >
        {name}
      </button>
    </div>
  );
}

export default Approve;
