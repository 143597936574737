import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/User/Home.jsx";
import Login from "./Pages/User/Login.jsx";
import Signup from "./Pages/User/Signup.jsx";
import Dashboard from "./Pages/Admin/Dashboard.jsx";
import Approvedusers from "./Pages/Admin/Approvedusers.jsx";
import Offerletter from "./Pages/User/Offerletter.jsx";
import Certificate from "./Pages/User/Certificate.jsx";
import Rejectedusers from "./Pages/Admin/Rejectedusers.jsx";
import AdminPrivateRoutes from "./PrivateRoutes/AdminPrivateRoutes.jsx";
import UserPrivateRoutes from "./PrivateRoutes/UserPrivateRoutes.jsx";
import UpdateUser from "./Pages/User/UpdateUser.jsx";
export const viewContext = createContext(null);
export const toggleContext = createContext(null);
function App() {
  const [view, setView] = useState(false);
  const [toggle, setToggle] = useState(false);
  return (
    <div className="App">
      <toggleContext.Provider value={{ toggle, setToggle }}>
        <viewContext.Provider value={{ view, setView }}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/Admin/Dashboard"
                element={
                  <AdminPrivateRoutes>
                    <Dashboard />
                  </AdminPrivateRoutes>
                }
              />
              <Route
                path="/Admin/Approvedusers"
                element={
                  <AdminPrivateRoutes>
                    <Approvedusers />
                  </AdminPrivateRoutes>
                }
              />
              <Route
                path="/Admin/Rejectedusers"
                element={
                  <AdminPrivateRoutes>
                    <Rejectedusers />
                  </AdminPrivateRoutes>
                }
              />
              <Route
                path="/User/Dashboard"
                element={
                  <UserPrivateRoutes>
                    <Home />
                  </UserPrivateRoutes>
                }
              />
              <Route
                path="/User/Offerletter"
                element={
                  <UserPrivateRoutes>
                    <Offerletter />
                  </UserPrivateRoutes>
                }
              />
              <Route
                path="/User/Certificate"
                element={
                  <UserPrivateRoutes>
                    <Certificate />
                  </UserPrivateRoutes>
                }
              />{" "}
              <Route
                path="/User/Update_User"
                element={
                  <UserPrivateRoutes>
                    <UpdateUser />
                  </UserPrivateRoutes>
                }
              />
            </Routes>
          </Router>
        </viewContext.Provider>
      </toggleContext.Provider>
    </div>
  );
}

export default App;
