import React from "react";
import logo from "../Images/TSS_logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { RiDashboard3Fill } from "react-icons/ri";
import { IoLogOut } from "react-icons/io5";
import logo_only from "../Images/TSS_Logo_Only.png";
import { RxHamburgerMenu } from "react-icons/rx";

function Navbar({ view, setView, toggle, setToggle }) {
  const navigate = useNavigate();
  const tabs = [
    {
      id: 1,
      name: "Dashboard",
      path: "/Admin/Dashboard",
      icon: <RiDashboardHorizontalFill size={view ? 28 : 18} />,
    },
    {
      id: 2,
      name: "Approved Users",
      path: "/Admin/Approvedusers",
      icon: <RiDashboard3Fill size={view ? 28 : 18} />,
    },
    {
      id: 3,
      name: "Rejected Users",
      path: "/Admin/Rejectedusers",
      icon: <RiDashboard3Fill size={view ? 28 : 18} />,
    },
  ];
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <div className="fixed top-0 left-0 flex flex-col md:w-auto w-full z-50">
      <div className="p-4 md:hidden flex w-full mb-4">
        <button
          onClick={() => setView(!view)}
          className={`bg-[#36454F] border-[#EEEDEB] border-2 w-fit mb-4 p-2 rounded-full  text-[#EEEDEB] text-xl duration-100 ease-in outline-none ${
            view ? `hidden ` : ``
          }`}
        >
          <RxHamburgerMenu size={24} />
        </button>
      </div>
      <div
        className={`transition-width duration-300 ${
          view ? `w-24` : `w-56`
        } hidden md:flex flex-col items-center justify-center bg-[#36454F] font-serif`}
      >
        <nav className="flex flex-col h-screen items-start  px-4 py-4">
          <div className="flex w-full justify-end  p-4 text-white">
            <button onClick={() => setView(!view)}>
              <RxHamburgerMenu size={24} />
            </button>
          </div>
          <ul className="w-full flex flex-col">
            {!view && (
              <div className="bg-[#EEEDEB] mb-6 rounded-sm shadow-lg">
                <img src={logo} alt="SCS_logo" className="h-fit w-fit" />
              </div>
            )}
            {view && (
              <div className="bg-[#EEEDEB] mb-6 rounded-sm shadow-lg">
                <img src={logo_only} alt="SCS_logo" className="h-fit w-full" />
              </div>
            )}
            {tabs.map((e) => {
              return (
                <li key={e?.id} className="w-full mb-2">
                  <NavLink
                    to={`${e.path}`}
                    className={({ isActive }) =>
                      `w-full px-3 py-2 rounded-sm flex items-center justify-center ${
                        isActive
                          ? `${
                              view
                                ? `border-b-4 border-[#F1F8E8] text-[#F1F8E8] `
                                : `bg-[#F1F8E8] text-[#1f1d49] `
                            }  text-[#1f1d49]`
                          : `text-[#F1F8E8]`
                      } hover:bg-[#D8EFD3] hover:text-[#1f1d49] `
                    }
                  >
                    {e?.icon && <span className="pr-px">{e?.icon}</span>}
                    {!view && (
                      <span className="md:flex hidden text-sm xl:text-base">
                        {e.name}
                      </span>
                    )}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <ul className="w-full flex  items-end h-full">
            <li
              onClick={() => {
                handleLogout();
              }}
              className="cursor-pointer w-full text-xl flex items-center justify-evenly font-semibold text-[#F1F8E8] px-3 py-2  hover:bg-[#D8EFD3] hover:text-[#1f1d49]"
            >
              {view ? "" : `Logout`} <IoLogOut size={28} />
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={`${
          view ? `block` : `hidden`
        } md:hidden flex flex-col fixed top-0 items-center justify-between bg-[#36454F] left-0 w-3/4 p-4 z-50 min-h-screen`}
      >
        <ul className="w-full flex flex-col mb-4">
          <button
            onClick={() => setView(!view)}
            className={`bg-[#36454F] border-[#EEEDEB] border-2 p-2 rounded-full self-end mb-2  text-[#EEEDEB] text-xl  outline-none ${
              view ? `w-fit ` : ``
            }`}
          >
            <RxHamburgerMenu size={24} />
          </button>
          <div className="bg-[#EEEDEB] mb-6 rounded-sm shadow-lg">
            <img src={logo} alt="SCS_logo" className="h-fit w-fit" />
          </div>
          {tabs.map((e) => (
            <li key={e.id} className="w-full mb-2">
              <NavLink
                to={e.path}
                className={({ isActive }) =>
                  ` px-3 py-2 rounded-sm flex items-center ${
                    isActive
                      ? `bg-[#F1F8E8] border-2 text-[#1f1d49]`
                      : `text-[#F1F8E8]`
                  } hover:bg-[#D8EFD3] hover:text-[#1f1d49] `
                }
              >
                <span className="pr-px">{e.icon}</span>
                <span className=" text-sm xl:text-base">{e.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
        <ul className="w-full">
          <li
            onClick={handleLogout}
            className="cursor-pointer w-full text-xl flex items-center justify-center font-semibold text-[#F1F8E8] px-3 py-2 hover:bg-[#D8EFD3] hover:text-[#1f1d49]"
          >
            Logout <IoLogOut size={28} className="ml-2" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
