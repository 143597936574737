import React from "react";
import bg from "../../../User/Components/Images/stamp.jpg";
import sign from "../../../User/Components/Images/signature.png";
function ViewOfferletter({ currentUser }) {
  return (
    <div
      // ref={printRef}
      onClick={(e) => e.stopPropagation()}
      className="relative  rounded my-5 h-[310mm] w-[210mm] mb-10 sm:mt-5 mt-5 mr-5 ml-5"
    >
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
        }}
        className="absolute inset-0 bg-cover bg-center -z-50"
      />

      <div className="flex flex-col mt-56">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-start justify-start mx-0">
            <h2 className="mx-10 text-sm">
              Ref. No.: {currentUser?.reference_number}
            </h2>
          </div>
          <div className="flex items-end justify-end">
            <h2 className="mx-10 text-sm">
              Date:{currentUser?.issue_date_of_letter}
            </h2>
          </div>
        </div>
        <div className="mt-10 flex flex-col items-start justify-start mx-10">
          <h1 className="text-sm font-bold">To,</h1>
          <h1 className="text-sm font-bold">
            Name: {currentUser?.student_name}
          </h1>
          <h1 className="text-sm font-bold">
            {currentUser?.course} in {currentUser?.branch}
          </h1>
          <h1 className="text-sm font-bold">
            Semester: {currentUser?.semester}
          </h1>
          <h1 className="text-sm font-bold">
            Enrollment no: {currentUser?.enrollment_number}
          </h1>
          <h1 className="text-sm font-bold">
            College name : {currentUser?.college_name}
          </h1>
        </div>
        <div className="flex items-center justify-center mt-4">
          <h1 className="text-sm font-bold">Offer Letter</h1>
        </div>
        <div className="flex flex-col items-start justify-start mx-10 text-sm mt-5">
          <h2>Dear {currentUser?.student_name},</h2>
          <p className="leading-6 text-justify">
            We are delighted to extend an internship offer for the position of
            Software Development Intern at Techskill Solutions effective from{" "}
            {currentUser?.internship_start_date}. You have to complete your
            daily task within a time limit. After successful completion of an
            internship, a certificate will be issued.
          </p>
          <ul className="mt-4">
            <h4 className="font-semibold">Internship Details</h4>
            <div className="internship-details ml-4 mt-2 ">
              <li>Start Date: {currentUser?.internship_start_date}</li>
              <li>End Date: {currentUser?.internship_end_date}</li>
            </div>
          </ul>
          <p className="mt-4 text-justify">
            We are excited about the prospect of working with you and believe
            this internship will offer valuable learning opportunities.
          </p>
          <p className="font-bold mt-6">Warm regards,</p>
          <img src={sign} alt="sign" className="mt-3 h-24 w-24" />
          <div className="mt-2 text-sm font-bold">
            <p>Authorized Signatory</p>
            <p>Techskill Solutions</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOfferletter;
