import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "./Components/Navbars/Navbar";
import UserOfferletter from "./Components/Models/UserOfferletter";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { toggleContext, viewContext } from "../../App.js";
import { BsDownload } from "react-icons/bs";
import { motion } from "framer-motion";
import "../User/Components/Cssfolder/TextColor.css";
import Typewriter from "typewriter-effect";
import OfferletterPdfRef from "./Components/Pdfs/OfferletterPdfRef.jsx";
function Offerletter() {
  const [currentUser, setCurrentUser] = useState(null);
  const [viewOfferlatter, setViewOfferlatter] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const { view, setView } = useContext(viewContext);
  const { toggle, setToggle } = useContext(toggleContext);
  const [isDownloadTriggered, setIsDownloadTriggered] = useState(false);
  const printRef = useRef();
  useEffect(() => {
    setIsloading(true);
    axios
      .get(`api/v1/current_user`)
      .then((res) => {
        console.log(res?.data);
        const myuser = res?.data?.user;
        setCurrentUser(myuser);
        setIsloading(false);
      })
      .then((err) => {
        console.log(err);
        setIsloading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-[#EEEDEB] flex max-md:flex-col w-full min-h-screen">
      <Navbar
        view={view}
        setView={setView}
        toggle={toggle}
        setToggle={setToggle}
      />
      <div className="flex flex-col w-full p-2 mt-8">
        <div
          className={` flex items-center justify-center p-4 flex-col ${
            view ? `md:ml-24 ml-0` : `md:ml-56 ml-0`
          }`}
        >
          {" "}
          <div className="w-full flex flex-col items-center justify-center ">
            {isloading === true || currentUser === null ? (
              <div className="w-3/4 rounded-md bg-white shadow-xl p-8 m-8">
                <div className="grid grid-cols-2  gap-10">
                  <Skeleton height={48} className="w-fit" />
                  <Skeleton height={48} className="w-fit" />
                </div>
                <div className="w-full mt-4">
                  {" "}
                  <Skeleton height={32} className="w-fit" />
                </div>
              </div>
            ) : currentUser?.reference_number ? (
              <div className="w-full flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center mb-8">
                  <motion.h1
                    className="sm:text-6xl text-4xl font-bold text-center color-flow"
                    initial={{ backgroundPosition: "0% 50%" }}
                    animate={{ backgroundPosition: ["0% 50%", "100% 50%"] }}
                    transition={{
                      duration: 3,
                      ease: "linear",
                      repeat: Infinity,
                      repeatType: "loop",
                    }}
                  >
                    Techskill Solutions
                  </motion.h1>
                  <div className="text-[#36454F]">
                    <Typewriter
                      style={{ color: "red" }}
                      onInit={(typewriter) => {
                        typewriter
                          .typeString("Web And Mobile Development")
                          .start()
                          .pauseFor(2000)
                          .deleteAll();
                      }}
                      options={{
                        loop: true,
                      }}
                    />
                  </div>
                </div>
                <div className="w-3/4 flex max-lg:flex-col items-center justify-between rounded-lg bg-[#36454F] hover:scale-110 text-[#FFFFFF] border border-[#36454F] shadow-xl p-8 m-8">
                  <h1 className="font-serif  text-xl mb-4">
                    Your Offer letter is Generated!
                  </h1>
                  <button
                    className="flex flex-row px-5 py-2 border-2 border-[#4cbc96] text-[#4cbc96] rounded-full hover:bg-[#4cbc96] hover:text-white transition duration-150 ease-in-out bg-white max-md:hidden"
                    onClick={() => {
                      setViewOfferlatter(!viewOfferlatter);
                    }}
                  >
                    View Offerletter
                  </button>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="flex flex-row md:hidden"
                  >
                    <button
                      className="flex  items-center justify-center text-xs sm:text-base flex-row px-5 py-2 border-2 border-[#4cbc96] text-[#4cbc96] rounded-full hover:bg-[#4cbc96] hover:text-white duration-150 ease-in bg-white"
                      onClick={() => {
                        setIsDownloadTriggered(true);
                      }}
                    >
                      <span className="mr-2 mt-1">
                        <BsDownload />
                      </span>
                      Download Letter
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center mb-8">
                  <motion.h1
                    className="sm:text-6xl text-4xl font-bold text-center color-flow"
                    initial={{ backgroundPosition: "0% 50%" }}
                    animate={{ backgroundPosition: ["0% 50%", "100% 50%"] }}
                    transition={{
                      duration: 3,
                      ease: "linear",
                      repeat: Infinity,
                      repeatType: "loop",
                    }}
                  >
                    Techskill Solutions
                  </motion.h1>
                  <div className="text-[#3c7bc4]">
                    <Typewriter
                      style={{ color: "red" }}
                      onInit={(typewriter) => {
                        typewriter
                          .typeString("Web And Mobile Development")
                          .start()
                          .pauseFor(2000)
                          .deleteAll();
                      }}
                      options={{
                        loop: true,
                      }}
                    />
                  </div>
                </div>
                <div className="w-3/4 flex flex-col items-center justify-between rounded-md bg-white shadow-xl p-8 m-8">
                  <h1 className="font-serif text-[#1f1d49] mb-4">
                    Your Offer letter is Not Generated Yet!
                  </h1>
                  <h1 className="font-serif text-[#1f1d49] mb-4">
                    Offer letter Status:{" "}
                    <span className="text-yellow-500 text-xl">Pending</span>
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {viewOfferlatter && (
        <UserOfferletter
          currentUser={currentUser}
          viewOfferlatter={viewOfferlatter}
          onClose={() => {
            setViewOfferlatter(false);
          }}
        />
      )}
      {isDownloadTriggered && (
        <OfferletterPdfRef
          currentUser={currentUser}
          printRef={printRef}
          isDownloadTriggered={isDownloadTriggered}
          setIsDownloadTriggered={setIsDownloadTriggered}
          viewOfferlatter={viewOfferlatter}
        />
      )}
    </div>
  );
}

export default Offerletter;
