import React, { useEffect } from "react";
import bg from "../../../User/Components/Images/stamp.jpg";
import sign from "../../../User/Components/Images/signature.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import toast from "react-hot-toast";
function CertificatePdfRef({
  currentUser,
  printRef,
  isDownloadTriggered,
  setIsDownloadTriggered,
}) {
  useEffect(() => {
    if (!isDownloadTriggered || !printRef.current) return;

    const generatePdf = async () => {
      try {
        const canvas = await html2canvas(printRef.current, { scale: 1.5 });
        const imgData = canvas.toDataURL("image/png");

        if (!imgData) {
          throw new Error("Image data is empty");
        }

        const pdf = new jsPDF("portrait", "mm", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // Positioning and scaling to fit A4
        pdf.save(`${currentUser?.student_name}_Certificate.pdf`);
      } catch (err) {
        toast.error("Please, Try again!");
        console.error("Error generating PDF:", err);
      } finally {
        setIsDownloadTriggered(false);
      }
    };

    generatePdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadTriggered]);
  return (
    <div
      ref={printRef}
      onClick={(e) => e.stopPropagation()}
      className={`absolute ${
        isDownloadTriggered === true ? `block` : `hidden`
      } left-[4000px] right-[4000px]  rounded my-5 h-[310mm] w-[210mm] mb-10 sm:mt-5 mt-5 mr-5 ml-5`}
    >
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
        }}
        className="absolute inset-0 bg-cover bg-center -z-50"
      />

      <div className="flex flex-col mt-56">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-start justify-start mx-0">
            <h2 className="mx-10 text-sm">
              <span className="font-semibold">Ref. No. : </span>{" "}
              {currentUser?.reference_number}
            </h2>
          </div>
          <div className="flex items-end justify-end">
            <h2 className="mx-10 text-sm">
              <span className="font-semibold">Date : </span>
              {currentUser?.issue_date_of_letter}
            </h2>
          </div>
        </div>

        <div className="flex items-center justify-center mt-32">
          <h1 className="text-sm font-bold">To whom it may concern</h1>
        </div>
        <div className="flex flex-col items-start justify-start mx-10 text-sm mt-5">
          <p className="flex justify-start items-start w-full">
            This is certify that
          </p>
          <h2 className="flex w-full items-center justify-center mb-px">
            {currentUser?.student_name},
          </h2>
          <p className="leading-7 text-justify">
            Enrollment Number : {currentUser?.enrollment_number}, Course :{" "}
            {currentUser?.course} in {currentUser?.branch}, Semester :{" "}
            {currentUser?.semester}, a student of {currentUser?.college_name}{" "}
            worked under my supervision as a Softwere Development intern from{" "}
            {currentUser?.internship_start_date} to{" "}
            {currentUser?.internship_end_date}. During this period of internship
            program with us, He/She was exposed to following different processes
            and were found sincere and hardworking.
          </p>
          <ul className="mt-4 leading-7">
            <div className="internship-details">
              <li>
                1. Layout of{" "}
                {currentUser?.internship_area === "App Development"
                  ? `App Design.`
                  : `Web Design.`}
              </li>
              <li>
                2. Workflow of{" "}
                {currentUser?.internship_area === "App Development"
                  ? `Mobile App Development.`
                  : `Web Development.`}
              </li>
              <li>3. Coding.</li>
              <li>4. Debugging.</li>
            </div>
          </ul>
          <p className="mt-2">We wish all the best for future endeavours.</p>
          <p className="font-bold mt-2">Warm regards,</p>
          <img src={sign} alt="sign" className="mt-3 h-24 w-24" />
          <div className="mt-2 text-sm font-bold">
            <p>Authorized Signatory</p>
            <p>Techskill Solutions</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificatePdfRef;
