import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import logo from "../User/Components/Images/TSS_logo.png";
import FormikInput from "./Components/Formik/FormikInput";
import Button from "./Components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ResetPassword from "./Components/Models/ResetPassword.jsx";
function Login() {
  const navigate = useNavigate();
  const [clientId, setClientId] = useState("");
  const [viewresetpass, setViewresetpass] = useState(false);
  //4cbc96
  // 1f1d49
  const fetchCredentials = () => {
    axios
      .get("api/v1/credentials")
      .then((response) => {
        console.log(response.data);
        setClientId(response.data?.client_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchCredentials();
  }, []);
  const handleSubmit = (values) => {
    axios
      .post("api/v1/users/login", {
        username: values.username,
        password: values.password,
        client_id: clientId,
      })
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          toast.success(res?.data?.message);
          localStorage.setItem("isAuthorised", true);
          localStorage.setItem("access_token", res?.data?.user?.access_token);
          localStorage.setItem("role", res?.data?.user?.role);
          localStorage.setItem("userid", res?.data?.user?.id);
          if (res?.data?.user?.email === "admin@gmail.com") {
            navigate("/Admin/Dashboard");
          } else navigate("/User/Dashboard");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error);
        console.log(err);
      });
  };
  const onClose = () => {
    setViewresetpass(false);
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-[#EEEDEB]">
      <div className="max-w-sm mx-4 sm:mx-auto bg-[#FAFAFA] shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h1 className="text-[#4cbc96] text-3xl mb-4 text-center font-semibold  flex items-center justify-center">
          <img src={logo} alt="scsimage" className="h-auto w-auto" />
        </h1>
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={Yup.object({
            username: Yup.string().required("* required"),
            password: Yup.string().required("* required"),
          })}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <FormikInput
                  name="username"
                  type="text"
                  label={"User Name"}
                  placeholder="Enter Your User Name"
                  onChange={(event) => {
                    setFieldValue("username", event.target.value);
                  }}
                />
              </div>
              <div className="mb-6">
                <FormikInput
                  name="password"
                  type="password"
                  label={"Password"}
                  placeholder="Enter Your Password"
                  onChange={(event) => {
                    setFieldValue("password", event.target.value);
                  }}
                />
              </div>
              <div className="flex justify-end items-center">
                <p
                  onClick={() => {
                    setViewresetpass(true);
                  }}
                  className="font-serif text-[#36454F] hover:text-[#4cbc96] underline hover:underline-offset-2 hover:cursor-pointer sm:text-lg"
                >
                  Forgot Password ?
                </p>
              </div>
              <div className="flex items-center justify-center mt-4">
                <Button name={"Signin"} type={"submit"} />
              </div>
              <div className="flex items-center justify-between mt-4">
                <div className="sm:flex flex-col">
                  <p className="font-serif text-[#36454F]">Don't</p>
                  <p className="font-serif text-[#36454F]">Have An Account ?</p>
                </div>
                <p
                  onClick={() => {
                    navigate("/signup");
                  }}
                  className="font-serif text-[#36454F] hover:text-[#4cbc96] underline hover:underline-offset-2 hover:cursor-pointer sm:text-lg"
                >
                  Sign-Up
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {viewresetpass && <ResetPassword onClose={onClose} />}
    </div>
  );
}

export default Login;
