import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "./Components/Formik/FormikInput";
import Button from "./Components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import logo from "../User/Components/Images/TSS_logo.png";
import FormikSelect from "./Components/Formik/FormikSelect.jsx";
import {
  Branches,
  Semesters,
  Courses,
  InternshipType,
  Internship_Area,
} from "../../Data.jsx";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { PiNumberOneBold } from "react-icons/pi";
import { PiNumberTwoBold } from "react-icons/pi";
import { PiNumberThreeBold } from "react-icons/pi";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import Loader from "../User/Components/Loaders/Loader.jsx";
import axios from "axios";
import toast from "react-hot-toast";

function Signup() {
  const navigate = useNavigate();
  const [next, setNext] = useState(0);
  const [clientId, setClientId] = useState("");
  const [viewPass, setViewPass] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const fetchCredentials = () => {
    axios
      .get("api/v1/credentials")
      .then((response) => {
        console.log(response.data);
        setClientId(response.data?.client_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchCredentials();
  }, []);
  const steps = [
    { number: 1, icon: PiNumberOneBold },
    { number: 2, icon: PiNumberTwoBold },
    { number: 3, icon: PiNumberThreeBold },
  ];
  const handleSubmit = (values) => {
    setIsloading(!isloading);
    axios
      .post("api/v1/users", {
        user: {
          email: (values?.email).trim(),
          password: (values?.password).trim(),
          username: (values?.username).trim(),
          student_name: (values?.fullname).trim(),
          mobile_number: (values?.mobile).trim(),
          college_name: (values?.college).trim(),
          enrollment_number: (values?.enrollmentno).trim(),
          branch: (values?.branch).trim(),
          semester: (values?.semester).trim(),
          course: (values?.course).trim(),
          internship_type: (values?.internship_type).trim(),
          internship_start_date: (values?.internship_start_date).trim(),
          internship_end_date: (values?.internship_end_date).trim(),
          internship_area: (values?.internship_area).trim(),
        },
        client_id: clientId,
      })
      .then((res) => {
        if (res?.status === 201) {
          toast.success(res?.data?.message);
          setIsloading(!isloading);
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        err?.response?.data?.error.map((e) => toast.error(e));
      });
  };
  const handleChange = (values) => {
    const name = values;
    const newname = name.replace(" ", "@");
    return newname;
  };
  const handleFullnameChange = (values) => {
    var name = values;
    const capitalized = name.replace(/\b\w/g, (character) =>
      character.toUpperCase()
    );
    return capitalized;
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-[#EEEDEB]">
      {isloading === true ? <Loader /> : null}
      <div className="flex max-lg:flex-col items-center justify-center  p-4">
        <div className="text-[#36454F] text-3xl text-center font-semibold font-mono flex items-center justify-center lg:w-3/4 xl:w-3/4">
          <img
            src={logo}
            alt="scsimage"
            className="max-sm:h-auto max-sm:w-auto  lg:h-3/4 lg:w-3/4 h-3/4 w-3/4 image"
          />
        </div>
        <div className="  w-full md:mx-auto mx-4 bg-[#FAFAFA] shadow-md rounded px-8 pt-6 pb-8 ">
          <h1 className="text-[#36454F] text-3xl mb-4 text-center font-semibold font-serif">
            Sign Up
          </h1>
          <div className="bg-gray-100 rounded-lg shadow-md p-6 mb-4 flex flex-col">
            <h1 className="text-gray-800 text-xl font-bold mb-4">
              Instructions
            </h1>
            <ul className="text-[#e1a148] text-base list-disc pl-5">
              <li className="mb-2">
                Please fill in the details according to your{" "}
                <span className="underline underline-offset-2">
                  college ID card
                </span>
                .
              </li>
              <li className="mb-2">
                Once submitted, the details cannot be edited.
              </li>
            </ul>
          </div>

          <div className="grid grid-cols-3 justify-around gap-4 mb-4">
            {steps.map((step, index) => (
              <div
                key={step.number}
                className="flex items-center justify-evenly w-full"
              >
                <span
                  className={` ${
                    next >= step.number
                      ? "text-green-600 font-extrabold"
                      : "opacity-40 text-green-300"
                  }`}
                >
                  ________
                </span>
                <span
                  className={`outline outline-offset-0 outline-green-500 rounded-full ${
                    next >= step.number
                      ? "text-green-600 outline-green-600"
                      : "opacity-40"
                  }`}
                >
                  {next >= step.number ? (
                    <IoCheckmarkDoneCircleOutline
                      size={25}
                      className="rounded-full"
                    />
                  ) : (
                    <step.icon
                      size={25}
                      className="rounded-full text-[#3c7bc4]"
                    />
                  )}
                </span>
              </div>
            ))}
          </div>

          <Formik
            initialValues={{
              fullname: "",
              email: "",
              mobile: "",
              college: "",
              branch: "",
              course: "",
              semester: "",
              enrollmentno: "",
              internship_type: "",
              internship_start_date: "",
              internship_end_date: "",
              usename: "",
              password: "",
              confirmpassword: "",
              internship_area: "",
            }}
            validationSchema={Yup.object({
              fullname: Yup.string()
                .min(6, "student name must be 6 character long")
                .required("* required"),
              email: Yup.string()
                .email("Invalid Email Id")
                .required("* required"),
              mobile: Yup.string()
                .min(10, "must be 10 digit")
                .max(10, "must be 10 digit")
                .required("* required"),
              college: Yup.string().required("* required"),
              branch: Yup.string().required("* required"),
              course: Yup.string().required("* required"),
              semester: Yup.string().required("* required"),
              enrollmentno: Yup.string().required("* required"),
              internship_type: Yup.string().required("* required"),
              internship_start_date: Yup.string().required("* required"),
              internship_end_date: Yup.string().required("* required"),
              password: Yup.string()
                .min(6, "Password should have at least 6 characters")
                .required("Please enter a password"),
              confirmpassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Please confirm your password"),
              internship_area: Yup.string().required("* required"),
              username: Yup.string().required("* required"),
            })}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <div className=" w-full">
                  {next === 0 ? (
                    <div>
                      <div className="mb-4">
                        <FormikInput
                          name="fullname"
                          type="text"
                          label={"Full Name"}
                          placeholder="Enter Full Name"
                          onChange={(event) => {
                            setFieldValue(
                              "fullname",
                              handleFullnameChange(event?.target?.value)
                            );
                          }}
                        />
                      </div>
                      <div className="mb-6">
                        <FormikInput
                          name="email"
                          type="email"
                          label={"Email"}
                          placeholder="Enter Your Email"
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-6">
                        <FormikInput
                          name="mobile"
                          type="number"
                          label={"Mobile No."}
                          placeholder="Enter Your Mobile No."
                          onChange={(event) => {
                            setFieldValue("mobile", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : next === 1 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div className="mb-4">
                        <FormikInput
                          label={"College Name"}
                          name={"college"}
                          placeholder={"College Name here...."}
                          onChange={(event) => {
                            setFieldValue("college", event.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <FormikSelect
                          label={"Branch Name"}
                          name={"branch"}
                          option={Branches}
                          onChange={(event) => {
                            setFieldValue("branch", event.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <FormikSelect
                          label={"Course"}
                          name={"course"}
                          option={Courses}
                          onChange={(event) => {
                            setFieldValue("course", event.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <FormikSelect
                          label={"Semester"}
                          name={"semester"}
                          option={Semesters}
                          onChange={(event) => {
                            setFieldValue("semester", event.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-6">
                        <FormikInput
                          name="enrollmentno"
                          type="enrollmentno"
                          label={"Enrollment No."}
                          placeholder="Enter Your Enrollment Number"
                          onChange={(event) => {
                            setFieldValue("enrollmentno", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div className="mb-4">
                          <FormikSelect
                            label="Type Of Internship"
                            name="internship_type"
                            option={InternshipType}
                            onChange={(event) => {
                              setFieldValue(
                                "internship_type",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <FormikSelect
                            label={"Area Of Internship"}
                            name={"internship_area"}
                            option={Internship_Area}
                            onChange={(event) => {
                              setFieldValue(
                                "internship_area",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <FormikInput
                            label={"Start Date"}
                            name={"internship_start_date"}
                            type={"date"}
                            placeholder={"DD/MM/YY"}
                            onChange={(event) => {
                              setFieldValue(
                                "internship_start_date",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <FormikInput
                            label={"End Date"}
                            name={"internship_end_date"}
                            type={"date"}
                            placeholder={"DD/MM/YY"}
                            onChange={(event) => {
                              setFieldValue(
                                "internship_end_date",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mb-4">
                          <FormikInput
                            label={"Create User Name"}
                            name={"username"}
                            type={"text"}
                            placeholder={"Username@123"}
                            onChange={(event) => {
                              setFieldValue(
                                "username",
                                handleChange(event?.target?.value)
                              );
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <FormikInput
                            label={"Create Passowrd"}
                            name={"password"}
                            type={`${viewPass === false ? `password` : `text`}`}
                            placeholder={"User123"}
                            onChange={(event) => {
                              setFieldValue("password", event.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <FormikInput
                            label={"Confirm Passowrd"}
                            name={"confirmpassword"}
                            type={`${viewPass === false ? `password` : `text`}`}
                            placeholder={"User123"}
                            onChange={(event) => {
                              setFieldValue(
                                "confirmpassword",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="w-full flex items-center justify-end">
                          <p
                            onClick={() => {
                              setViewPass(!viewPass);
                            }}
                            className="flex"
                          >
                            {viewPass === false
                              ? `show password`
                              : `hide password`}{" "}
                            {viewPass === false ? (
                              <IoIosEye size={28} />
                            ) : (
                              <IoIosEyeOff size={28} />
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`flex items-center ${
                    next === 0 ? `justify-center` : `justify-between`
                  } mt-4`}
                >
                  {next >= 2 ? (
                    <>
                      <Button
                        name={"Back"}
                        type={"button"}
                        onClick={() => {
                          setNext(next - 1);
                        }}
                      />
                      <Button name={"Signup"} type={"submit"} />
                    </>
                  ) : null}
                  {next === 0 ? (
                    <Button
                      disabled={
                        values.fullname === 0 ||
                        errors.fullname ||
                        values.email === "" ||
                        errors.email ||
                        values.mobile === "" ||
                        errors.mobile
                      }
                      name={"Next"}
                      type={"button"}
                      onClick={() => {
                        setNext(next + 1);
                      }}
                    />
                  ) : next === 1 ? (
                    <>
                      <Button
                        name={"Back"}
                        type={"button"}
                        onClick={() => {
                          setNext(next - 1);
                        }}
                      />
                      <Button
                        disabled={
                          values.fullname === 0 ||
                          values.email === "" ||
                          values.mobile === "" ||
                          values.college === "" ||
                          values.branch === "" ||
                          values.course === "" ||
                          values.semester === "" ||
                          values.enrollmentno === ""
                        }
                        name={"Next"}
                        type={"button"}
                        onClick={() => {
                          setNext(next + 1);
                        }}
                      />
                    </>
                  ) : next === 1 ? (
                    <>
                      <Button
                        name={"Back"}
                        type={"button"}
                        onClick={() => {
                          setNext(next - 1);
                        }}
                      />
                      <Button
                        name={"Next"}
                        type={"button"}
                        onClick={() => {
                          setNext(next + 1);
                        }}
                      />
                    </>
                  ) : null}
                </div>
                <div className="flex items-center justify-between mt-4">
                  <div className="flex flex-col">
                    <p className="font-serif text-[#1f1d49]">Already</p>
                    <p className="font-serif text-[#1f1d49]">
                      Have An Account ?
                    </p>
                  </div>
                  <p
                    onClick={() => {
                      navigate("/");
                    }}
                    className="font-serif text-[#1f1d49] hover:text-[#4cbc96] underline hover:underline-offset-2 hover:cursor-pointer sm:text-lg"
                  >
                    Sign-In
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Signup;
