import { Form, Formik } from "formik";
import React, { useState } from "react";
import FormikInput from "../Formik/FormikInput";
import * as Yup from "yup";
import Button from "../Buttons/Button";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../Loaders/Loader";

function ResetPassword({ onClose }) {
  const [viewotp, setviewOtp] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const handleSubmit = (values) => {
    setIsloading(true);
    axios
      .post("api/v1/users/forgot_password", {
        email: values?.email,
      })
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setviewOtp(true);
        localStorage.setItem("emailforotp", values?.email);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.error);
        setIsloading(false);
      });
  };
  const handleResetpass = (values) => {
    setIsloading(true);
    axios
      .post("api/v1/users/reset_password", {
        email: localStorage.getItem("emailforotp"),
        otp: `${values?.otp}`,
        new_password: values?.newpass,
      })
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        localStorage.removeItem("emailforotp");
        setIsloading(false);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.error);
        setIsloading(false);
        onClose();
      });
  };
  return (
    <div className="fixed inset-0 bg-cover bg-center bg-black z-50 flex items-center justify-center bg-opacity-70">
      {isloading === true ? <Loader /> : null}
      <div className="w-full sm:mx-auto mx-4 sm:w-3/4 md:w-2/4 lg:w-2/5 xl:w-1/4 flex items-center justify-center">
        <div className="flex flex-col items-center justify-center bg-white p-4 rounded-md w-full">
          <div className="w-full flex items-center justify-end ">
            <button
              className=" hover:text-white text-gray-900 hover:bg-gray-700 bg-gray-400 rounded-full p-1"
              onClick={onClose}
            >
              <FaTimes size={24} />
            </button>
          </div>
          <h1 className="text-xl text-[#4cbc96] mb-4 font-semibold">
            Reset Password
          </h1>
          {!viewotp && (
            <div className="flex w-full">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid Email Id")
                    .required("* required"),
                })}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ setFieldValue }) => (
                  <Form className="grid grid-cols-1 items-center justify-between w-full">
                    <FormikInput
                      name={"email"}
                      label={"Registered Email Id"}
                      placeholder={"abc@gmail.com"}
                      type={"email"}
                      onChange={(event) => {
                        setFieldValue("email", event?.target?.value);
                      }}
                    />
                    <div className="">
                      <Button name={"Send Otp"} type={"submit"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {viewotp && (
            <div className="grid grid-cols-1 w-full ">
              <div className="flex flex-col text-gray-400">
                <span>Registered Email Id :</span>
                <span className="text-[#4cbc96]">
                  {localStorage.getItem("emailforotp")}
                </span>
              </div>
              <Formik
                initialValues={{
                  otp: "",
                  newpass: "",
                  confirmpass: "",
                }}
                validationSchema={Yup.object({
                  otp: Yup.string().required("* required"),
                  newpass: Yup.string()
                    .min(6, "Password should have at least 6 characters")
                    .required("Please enter a password"),
                  confirmpass: Yup.string()
                    .oneOf([Yup.ref("newpass"), null], "Passwords must match")
                    .required("Please confirm your password"),
                })}
                onSubmit={(values) => {
                  handleResetpass(values);
                }}
              >
                {({ setFieldValue }) => (
                  <Form className="grid grid-cols-1 items-center justify-between w-full mt-4">
                    <FormikInput
                      name={"otp"}
                      label={"Enter Otp"}
                      placeholder={"472178"}
                      type={"number"}
                      onChange={(event) => {
                        setFieldValue("otp", event?.target?.value);
                      }}
                    />{" "}
                    <FormikInput
                      name={"newpass"}
                      label={"Enter New Password"}
                      placeholder={"Abc@123"}
                      type={"text"}
                      onChange={(event) => {
                        setFieldValue("newpass", event?.target?.value);
                      }}
                    />{" "}
                    <FormikInput
                      name={"confirmpass"}
                      label={"Confirm Password"}
                      placeholder={"Abc@123"}
                      type={"text"}
                      onChange={(event) => {
                        setFieldValue("confirmpass", event?.target?.value);
                      }}
                    />
                    <div className="">
                      <Button name={"Submit"} type={"submit"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
