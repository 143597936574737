import React, { useRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import ViewCertificate from "../../../User/Components/Pdfs/ViewCertificate";
import CertificatePdfRef from "../../../User/Components/Pdfs/CertificatePdfRef";
function Certificate({ onClose, selectedusers, viewCertificate }) {
  const printRef = useRef();
  const [isDownloadTriggered, setIsDownloadTriggered] = useState(false);
  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50"
      onClick={onClose}
      aria-modal="true"
      role="dialog"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-center justify-center my-auto z-50 mb-20 "
      >
        <div className="w-full flex items-center justify-end pt-4 pr-4">
          <button
            className=" text-white hover:text-gray-900 hover:bg-white rounded-full p-1"
            onClick={onClose}
          >
            <FaTimes size={24} />
          </button>
        </div>
        <div onClick={(e) => e.stopPropagation()} className="flex flex-row ">
          <button
            className="flex flex-row px-5 py-2 border-2 border-[#4cbc96] text-[#4cbc96] rounded-full hover:bg-[#4cbc96] hover:text-white duration-150 ease-in bg-white"
            onClick={() => {
              setIsDownloadTriggered(true);
            }}
          >
            <span className="mr-2 mt-1">
              <BsDownload />
            </span>
            Download Certificate
          </button>
        </div>
        {viewCertificate && <ViewCertificate currentUser={selectedusers} />}
        {isDownloadTriggered && (
          <CertificatePdfRef
            currentUser={selectedusers}
            isDownloadTriggered={isDownloadTriggered}
            printRef={printRef}
            setIsDownloadTriggered={setIsDownloadTriggered}
          />
        )}
      </div>
    </div>
  );
}

export default Certificate;
