import React from "react";
import { Navigate } from "react-router-dom";

function AdminPrivateRoutes({ children }) {
  const isAuthorised = localStorage.getItem("isAuthorised");
  const role = localStorage.getItem("role");
  if (!isAuthorised) {
    return <Navigate to="/" />;
  }
  if (role === "admin" && isAuthorised) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
}

export default AdminPrivateRoutes;
