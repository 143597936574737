import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Components/Navbars/Navbar";
import { viewContext } from "../../App";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import { CiEdit } from "react-icons/ci";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "./Components/Formik/FormikInput";
import Button from "./Components/Buttons/Button";
import Swal from "sweetalert2";
import logo from "./Components/Images/TSS_logo.png";
function UpdateUser() {
  const [currentUser, setCurrentUser] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const { view, setView } = useContext(viewContext);
  const [isupdate, setIsupdate] = useState(false);
  useEffect(() => {
    setIsloading(true);
    getCurrentUser();
  }, []);
  const getCurrentUser = () => {
    axios
      .get(`api/v1/current_user`)
      .then((res) => {
        const myuser = res?.data?.user;
        setCurrentUser(myuser);
        setIsloading(false);
      })
      .then((err) => {
        console.log(err);
        setIsloading(false);
      });
  };
  const handleChange = (values) => {
    const name = values;
    const newname = name.replace(" ", "@");
    return newname;
  };
  const handleUpdateUsername = (values) => {
    Swal.fire({
      title: "Are you sure?",
      text: `New User Name : ${values?.username}`,
      imageUrl: logo,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update",
      color: "#36454F",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`api/v2/users/${currentUser?.id}/user_update`, {
            username: values?.username,
          })
          .then((res) => {
            console.log(res);
            Swal.fire({
              title: "Updated !",
              text: "UserName is Updated !",
              icon: "success",
            }).then((success) => {
              getCurrentUser();
            });
            setIsupdate(!isupdate);
          })
          .catch((err) => {
            console.log(err);
            setIsupdate(!isupdate);
          });
      } else if (result?.isDismissed) {
        setIsupdate(!isupdate);
      }
    });
  };
  return (
    <div className="bg-[#EEEDEB] flex max-md:flex-col w-full min-h-screen">
      <Navbar view={view} setView={setView} />
      <div className="flex flex-col w-full p-2 mt-8">
        <div
          className={` flex items-center justify-center p-4 flex-col ${
            view ? `md:ml-24 ml-0` : `md:ml-56 ml-0`
          }`}
        >
          <div className="w-full flex flex-col items-center justify-center ">
            {isloading === true || currentUser === null ? (
              <div className="w-3/4 rounded-md bg-white shadow-xl p-8 m-8">
                <div className="grid grid-cols-2  gap-10">
                  <Skeleton height={48} className="w-fit" />
                  <Skeleton height={48} className="w-fit" />
                </div>
                <div className="w-full mt-4">
                  {" "}
                  <Skeleton height={32} className="w-fit" />
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center mb-8">
                  <motion.h1
                    className="sm:text-6xl text-4xl font-bold text-center color-flow"
                    initial={{ backgroundPosition: "0% 50%" }}
                    animate={{ backgroundPosition: ["0% 50%", "100% 50%"] }}
                    transition={{
                      duration: 3,
                      ease: "linear",
                      repeat: Infinity,
                      repeatType: "loop",
                    }}
                  >
                    Techskill Solutions
                  </motion.h1>
                  <div className="text-[#36454F]">
                    <Typewriter
                      style={{ color: "red" }}
                      onInit={(typewriter) => {
                        typewriter
                          .typeString("Web And Mobile Development")
                          .start()
                          .pauseFor(2000)
                          .deleteAll();
                      }}
                      options={{
                        loop: true,
                      }}
                    />
                  </div>
                </div>
                <div className="w-3/4 flex flex-col  rounded-lg bg-[#36454F] hover:scale-110 text-[#FFFFFF] border border-[#36454F] shadow-xl p-8 m-8">
                  <h1 className="font-serif  text-xl mb-4">
                    Update Your Profile
                  </h1>
                  <div
                    className={`bg-[#EEEDEB]  ${
                      isupdate
                        ? `bg-opacity-100 `
                        : `bg-opacity-5 text-[#EEEDEB]`
                    } rounded-md p-4 flex items-center justify-between`}
                  >
                    {!isupdate ? (
                      <h1>User Name : {currentUser?.username}</h1>
                    ) : (
                      <div className="text-xs w-full">
                        <Formik
                          initialValues={{
                            username: currentUser?.username || "",
                          }}
                          validationSchema={Yup.object({
                            username: Yup.string().required("* required"),
                          })}
                          onSubmit={(values) => {
                            handleUpdateUsername(values);
                          }}
                        >
                          {({ setFieldValue, values }) => (
                            <Form className="flex w-full  max-sm:flex-col justify-between">
                              <div className="text-[#36454F]">
                                <FormikInput
                                  name={"username"}
                                  label={"User_Name"}
                                  type={"text"}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "username",
                                      handleChange(event?.target?.value)
                                    );
                                  }}
                                />
                              </div>
                              <div className="mt-3">
                                <Button type={"submit"} name={"Update"} />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    )}

                    {!isupdate && (
                      <div>
                        <div
                          className=" w-fit p-2 rounded-tl-lg rounded-br-lg cursor-pointer hover:bg-white hover:text-gray-600"
                          onClick={() => {
                            setIsupdate(!isupdate);
                          }}
                        >
                          <CiEdit size={25} className="" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateUser;
