import { ErrorMessage, Field } from "formik";
import React from "react";

function FormikInput({ label, name, type, placeholder, onChange }) {
  return (
    <div className="flex flex-col">
      <label
        className="block text-[#36454F] text-sm font-bold  mb-2 max-md:text-xs"
        htmlFor={label}
      >
        {label}
      </label>
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        className="px-3 py-2 focus:ring focus:outline-none border border-gray-400 ring-[#36454F] ring-opacity-50"
      />
      <ErrorMessage component={"div"} name={name} className="text-red-400 " />
    </div>
  );
}

export default FormikInput;
