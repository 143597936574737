import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Components/Navbars/Navbar";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../Admin/Components/Loaders/Loader";
import MdFormikSelect from "../User/Components/Formik/MdFormikSelect.jsx";
import { InternshipType, Semesters, Years } from "../../Data";
import { Form, Formik } from "formik";
import { FaFilterCircleXmark } from "react-icons/fa6";
import Normal from "./Components/Buttons/Normal.jsx";
import UserDetails from "./Components/Models/UserDetails.jsx";
import { toggleContext, viewContext } from "../../App.js";
import { motion } from "framer-motion";
import "./Components/Cssfolder/TextColor.css";
import Typewriter from "typewriter-effect";
import Button from "./Components/Buttons/Button.jsx";
import FormikInput from "../User/Components/Formik/FormikInput.jsx";
function Rejectedusers() {
  const [users, setUsers] = useState([]);
  const [filteredusers, setFilteredUsers] = useState([]);
  const [selectedusers, setSelectedUsers] = useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isloading, setIsloading] = useState(false);
  const [filters, setFilters] = useState({
    semester: "",
    year: "",
    internship_type: "",
  });
  const { view, setView } = useContext(viewContext);
  const { toggle, setToggle } = useContext(toggleContext);
  useEffect(() => {
    const targetElement = document.getElementById("targetDiv");
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  }, [users]);
  useEffect(() => {
    const targetElement = document.getElementById("targetDiv");
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  }, [users]);
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  const fetchUsers = () => {
    setIsloading(true);
    axios
      .get("api/v1/users")
      .then((res) => {
        const allusers = res?.data?.users;
        let filtered = allusers.filter((data) => data.status === "Rejected");
        if (filters) {
          if (filters.semester) {
            filtered = filtered.filter(
              (data) => data.semester === filters.semester
            );
          }
          if (filters?.internship_type) {
            filtered = filtered.filter(
              (data) => data?.internship_type === filters?.internship_type
            );
          }
          if (filters.year) {
            const yearFilter = parseInt(filters.year, 10);
            if (!isNaN(yearFilter)) {
              filtered = filtered.filter((data) => {
                const [day, month, year] =
                  data.internship_start_date.split("-");
                const startDate = new Date(`${year}-${month}-${day}`);
                const startYear = startDate.getFullYear();
                return startYear === yearFilter;
              });
            } else {
              console.error("Invalid year filter:", filters.year);
            }
          }
        }

        setFilteredUsers(filtered);
        setUsers(allusers.filter((data) => data.status === "Rejected"));
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  const columns = [
    { id: "Sr No", label: "Sr No.", minWidth: 80 },
    { id: "student_name", label: "User Name", minWidth: 210 },
    { id: "email", label: "Email Id", minWidth: 240 },
    { id: "internship_type", label: "Type", minWidth: 100 },
    { id: "semester", label: "Semester", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "Details", label: "Details", minWidth: 100 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const handleDetails = (id) => {
    const selected = users.find((data) => data.id === id);
    console.log(selected);
    setSelectedUsers(selected);
  };
  const onClose = () => {
    setSelectedUsers(null);
  };
  return (
    <div className="bg-[#EEEDEB] flex  max-md:flex-col w-full min-h-screen ">
      <Navbar
        view={view}
        setView={setView}
        toggle={toggle}
        setToggle={setToggle}
      />{" "}
      {isloading === true ? <Loader /> : null}
      <div className="flex flex-col w-full p-4  items-center justify-center mt-8">
        <div
          className={` flex items-center justify-center p-4 flex-col ${
            view ? `md:ml-24 ml-0` : `md:ml-56 ml-0`
          }`}
        >
          <div className="flex flex-col items-center justify-center mb-8">
            <motion.h1
              className="sm:text-6xl text-4xl font-bold text-center color-flow"
              initial={{ backgroundPosition: "0% 50%" }}
              animate={{ backgroundPosition: ["0% 50%", "100% 50%"] }}
              transition={{
                duration: 3,
                ease: "linear",
                repeat: Infinity,
                repeatType: "loop",
              }}
            >
              Techskill Solutions
            </motion.h1>
            <div className="text-[#36454F]">
              <Typewriter
                style={{ color: "red" }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Web And Mobile Development")
                    .start()
                    .pauseFor(2000)
                    .deleteAll();
                }}
                options={{
                  loop: true,
                }}
              />
            </div>
          </div>
          <div className="w-full flex  text-xs ">
            <Formik
              initialValues={{
                semester: "",
                internship_type: "",
                year: "",
                reference_number: "",
              }}
              onSubmit={(values) => {
                // Handle form submission if needed
              }}
            >
              {({ setFieldValue, values }) => (
                <div
                  className={`flex max-xl:flex-col  w-full  items-center justify-between`}
                >
                  <div
                    className={`grid grid-cols-1 sm:w-1/4 w-3/4 mb-6 md:mr-4`}
                  >
                    <div className="flex items-start max-md:justify-between space-x-2">
                      <FormikInput
                        label={"Reference No"}
                        name={"reference_number"}
                        placeholder={"Search by Reference No"}
                        option={Semesters}
                        onChange={(event) => {
                          setFieldValue("reference_number", event.target.value);
                        }}
                      />
                      <div className="mt-3">
                        <Button
                          name={"Search"}
                          type={"button"}
                          onClick={() => {
                            setFilters({
                              reference_number: values?.reference_number,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Form className="grid w-3/4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1  gap-4">
                    <div>
                      <MdFormikSelect
                        label={"Semesters"}
                        name={"semester"}
                        option={Semesters}
                        onChange={(event) => {
                          setFieldValue("semester", event.target.value);
                          handleFilterChange(event, "semester");
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <MdFormikSelect
                        label={"Internship Type"}
                        name={"internship_type"}
                        option={InternshipType}
                        onChange={(event) => {
                          setFieldValue("internship_type", event.target.value);
                          handleFilterChange(event, "internship_type");
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <MdFormikSelect
                        label={"Year"}
                        name={"year"}
                        option={Years}
                        onChange={(event) => {
                          setFieldValue("year", event.target.value);
                          handleFilterChange(event, "year");
                        }}
                      />
                    </div>
                    <div className="mt-3 flex items-start justify-start">
                      <Button
                        type={"button"}
                        name={"Clear Filter"}
                        icons={<FaFilterCircleXmark />}
                        onClick={() => {
                          setFilteredUsers(users);
                          setFieldValue("year", "");
                          setFieldValue("internship_type", "");
                          setFieldValue("semester", "");
                          setFieldValue("reference_number", "");
                          setFilters({
                            semester: "",
                            year: "",
                            internship_type: "",
                            reference_number: "",
                            view_certificate: "",
                          });
                        }}
                      />
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
          <div
            id="targetDiv"
            className="grid grid-cols-1 p-2 items-center justify-center w-full  "
          >
            {" "}
            <Paper sx={{ width: "100%" }}>
              <TableContainer
                sx={{
                  maxHeight: 500,
                  maxWidth: "100%",
                  overflow: "auto",
                  fontSize: "10px",
                }}
              >
                {" "}
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: "center",
                            backgroundColor: "#36454F",
                            fontFamily: "serif",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#ffffff",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredusers.length !== 0 ? (
                      filteredusers
                        .sort((a, b) => parseInt(b?.id) - parseInt(a?.id))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={"center"}
                                    style={{
                                      color: "gray",
                                      fontFamily: "serif",
                                    }}
                                  >
                                    {column.id === "Sr No" ? (
                                      <span className="text-[#1f1d49]">
                                        {index + 1}
                                      </span>
                                    ) : column.id === "Details" ? (
                                      <div>
                                        {" "}
                                        <Normal
                                          name={"View"}
                                          type={"button"}
                                          onClick={() => {
                                            handleDetails(row?.id);
                                          }}
                                        />{" "}
                                      </div>
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableCell>No Data Found</TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredusers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
      {selectedusers ? (
        <UserDetails selectedUser={selectedusers} onClose={onClose} />
      ) : null}
    </div>
  );
}

export default Rejectedusers;
