import { ErrorMessage, Field } from "formik";
import React from "react";

function FormikSelect({ option, label, name, onChange }) {
  return (
    <div className="flex flex-col">
      <label
        className="block text-[#1f1d49] text-sm font-bold mb-2"
        htmlFor={label}
      >
        {label}
      </label>
      <Field
        as="select"
        name={name}
        onChange={onChange}
        className="px-3 py-2 focus:ring focus:outline-none border border-gray-400 ring-[#36454F] ring-opacity-50"
      >
        <option value={""}>{"select an option"}</option>
        {option.map((e) => {
          return <option value={e?.name}>{e?.name}</option>;
        })}
      </Field>
      <ErrorMessage component={"div"} name={name} className="text-red-400 " />
    </div>
  );
}

export default FormikSelect;
