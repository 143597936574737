import React from "react";

function Assgin({ name, type, disabled, onClick, icon }) {
  return (
    <div className="flex items-center justify-center mt-4">
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={`bg-[#FFFAFA] hover:bg-[#36454F] text-[#36454F] hover:text-[#FFFAFA] border border-[#36454F] font-bold py-2 px-4 rounded  focus:ring-2 focus:outline-none focus:ring-opacity-70 ring-[#4cbc96] font-mono text-xs flex items-center justify-center ${
          disabled ? "cursor-not-allowed" : null
        }`}
      >
        {icon && icon}
        {name}
      </button>
    </div>
  );
}

export default Assgin;
