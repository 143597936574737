export const Colleges = [
  {
    id: "1",
    name: "Silver Oak University",
  },
  {
    id: "2",
    name: "Nirma University",
  },
  {
    id: "3",
    name: "Government Polytechnic, Ahmedabad",
  },
  {
    id: "4",
    name: "Government Polytechnic, Gandhinagar",
  },
  {
    id: "5",
    name: "Government Polytechnic for Girls, Ahmedabad",
  },
  {
    id: "6",
    name: "Rc technical institute, Ahmedabad",
  },
  {
    id: "7",
    name: "Sal Engineering And Technical Institute, Ahmedabad",
  },
  {
    id: "8",
    name: "SAL INSTITUTE OF DIPLOMA STUDIES, Ahmedabad",
  },
  {
    id: "9",
    name: "L J institute of diploma studies, Ahmedabad",
  },
  {
    id: "10",
    name: "SHREE VASUDEVBHAI AND KANTIBHAI PATEL INSTITUTE OF ENGINEERING, Kadi",
  },
];
export const Branches = [
  {
    id: "1",
    name: "Computer Sceience and Engineering",
  },
  {
    id: "2",
    name: "Data science",
  },
  {
    id: "3",
    name: "Software engineering",
  },
  {
    id: "4",
    name: "Networking",
  },
  {
    id: "5",
    name: "Cybersecurity",
  },
  {
    id: "6",
    name: "Robotics",
  },
  {
    id: "7",
    name: "Computer graphics",
  },
  {
    id: "8",
    name: "Artificial intelligence",
  },
  {
    id: "9",
    name: "Machine Learning",
  },
  {
    id: "10",
    name: "Artificial Intelligence and Machine Learning",
  },
  {
    id: "11",
    name: "Information Technology",
  },
  {
    id: "11",
    name: "Computer Engineering",
  },
  {
    id: "12",
    name: "Electronics & Communication Engineering (EC)",
  },
  {
    id: "13",
    name: "Information & Communication Technology (ICT)",
  },
];
export const Semesters = [
  {
    id: "1",
    name: "1",
  },
  {
    id: "2",
    name: "2",
  },
  {
    id: "3",
    name: "3",
  },
  {
    id: "4",
    name: "4",
  },
  {
    id: "5",
    name: "5",
  },
  {
    id: "6",
    name: "6",
  },
  {
    id: "7",
    name: "7",
  },
  {
    id: "8",
    name: "8",
  },
];
export const Courses = [
  {
    id: "1",
    name: "B.tech",
  },
  {
    id: "2",
    name: "M.tech",
  },
  {
    id: "3",
    name: "BCA",
  },
  {
    id: "4",
    name: "MCA",
  },
  {
    id: "5",
    name: "BE",
  },
  {
    id: "6",
    name: "ME",
  },
  {
    id: "7",
    name: "B.Sc",
  },
  {
    id: "8",
    name: "M.sc",
  },
  {
    id: "8",
    name: "Diploma",
  },
];
export const InternshipType = [
  {
    id: "1",
    name: "Summer",
  },
  {
    id: "2",
    name: "Winter",
  },
];
export const Internship_Area = [
  { id: "1", name: "Web Development" },
  { id: "2", name: "App Development" },
];
export const View_Certificate = [
  { id: "1", name: "Share Certificate" },
  { id: "2", name: "Shared" },
];

const generateYears = (numYears) => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: numYears }, (v, i) => ({
    id: i + 1,
    name: currentYear - i,
  }));
};

export const Years = generateYears(10);
