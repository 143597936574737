import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
function Loader(props) {
  return (
    <div className="fixed inset-0 bg-[#2F3645] bg-cover bg-center flex items-center justify-center bg-opacity-70 z-50">
      <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
        <CircularProgress color="success" />
      </Stack>
    </div>
  );
}

export default Loader;
