import React, { useContext, useEffect, useState } from "react";
import Navbar from "../User/Components/Navbars/Navbar.jsx";
import axios from "axios";
import { FaCodeBranch, FaUserCheck } from "react-icons/fa6";
import { RiPagesFill } from "react-icons/ri";
import {
  MdAccountCircle,
  MdDateRange,
  MdEmail,
  MdLabel,
  MdPerson,
  MdPhone,
  MdSchool,
  MdTimeline,
} from "react-icons/md";
import { AiOutlineFieldNumber } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { BsPinMapFill } from "react-icons/bs";
import { toggleContext, viewContext } from "../../App.js";

function Home() {
  const [isloading, setIsloading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { view, setView } = useContext(viewContext);
  const { toggle, setToggle } = useContext(toggleContext);
  useEffect(() => {
    setIsloading(true);
    axios
      .get(`api/v1/current_user`)
      .then((res) => {
        console.log(res?.data);
        const myuser = res?.data?.user;

        setCurrentUser(myuser);
        setIsloading(false);
      })
      .then((err) => {
        console.log(err);
        setIsloading(false);
      });
    setToggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-[#EEEDEB] flex max-md:flex-col w-full min-h-screen">
      <Navbar
        view={view}
        setView={setView}
        toggle={toggle}
        setToggle={setToggle}
      />
      <div className="flex flex-col w-full p-2 mt-12">
        <div
          className={` flex items-center justify-center p-4 flex-col ${
            view ? `md:ml-24 ml-0` : `md:ml-56 ml-0`
          }`}
        >
          <div className="w-full items-center md:overflow-auto bg-[#EEEDEB] border border-[#36454F] rounded-t-xl">
            <div className=" mb-2 text-center bg-[#36454F] rounded-t-xl p-4">
              <h2 className="text-3xl font-semibold text-white">
                User Details {""}
              </h2>
            </div>
            <div className="p-4">
              {isloading === true ? (
                <div className="p-4 w-full mb-2">
                  <div className="">
                    <Skeleton height={32} width={200} />
                    <Skeleton height={32} width={200} />
                  </div>
                </div>
              ) : (
                <div className="p-4 grid grid-cols-1 w-full mb-2">
                  <div className="flex items-center  justify-end">
                    <FaUserCheck className="mr-3 text-gray-600" size={28} />
                    <span className="font-medium pr-2">Username : </span>{" "}
                    {currentUser?.username}
                  </div>
                  <div className="flex items-center mb-2 justify-end">
                    <RiPagesFill className="mr-3 text-gray-600" size={28} />
                    <span className="font-medium pr-2">Status : </span>{" "}
                    {currentUser?.status === "pending" ? (
                      <span className="text-xl text-orange-400">
                        {currentUser?.status}
                      </span>
                    ) : currentUser?.status === "Approved" ? (
                      <span className="text-xl text-green-500 font-semibold">
                        {currentUser?.status}
                      </span>
                    ) : (
                      <span className="text-xl text-red-400">
                        {currentUser?.status}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {isloading === true ? (
                  <>
                    <div className="bg-white shadow-2xl rounded-lg p-4">
                      <Skeleton height={200} />
                    </div>
                    <div className="bg-white shadow-2xl rounded-lg p-4">
                      <Skeleton height={200} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="bg-white shadow-2xl rounded-lg p-4 max-sm:text-sm">
                      <h3 className="text-lg font-semibold mb-4">
                        Contact Details:
                      </h3>
                      <div className="flex items-center mb-2 flex-wrap">
                        <MdPerson className="mr-3 text-green-600" />
                        <span className="font-medium pr-2">Name:</span>{" "}
                        {currentUser?.student_name}
                      </div>
                      <div className="flex items-center mb-2 flex-wrap">
                        <MdEmail className="mr-3 text-blue-600" />
                        <span className="font-medium pr-2">Email:</span>{" "}
                        <span className="line-clamp-1">
                          {currentUser?.email}
                        </span>
                      </div>
                      <div className="flex items-center mb-2 flex-wrap">
                        <MdPhone className="mr-3 text-indigo-600" />
                        <span className="font-medium pr-2">
                          Mobile Number:
                        </span>{" "}
                        {currentUser?.mobile_number}
                      </div>
                    </div>
                    <div className="bg-white shadow-xl rounded-lg p-4">
                      <h3 className="text-lg font-semibold mb-4">
                        College Details:
                      </h3>
                      <div className="flex items-center mb-2 flex-wrap">
                        <MdAccountCircle className="mr-3 text-red-600" />
                        <span className="font-medium pr-2">College:</span>{" "}
                        <span className="line-clamp-1">
                          {currentUser?.college_name}
                        </span>
                      </div>
                      <div className="flex items-center mb-2 flex-wrap">
                        <MdSchool className="mr-3 text-purple-600" />
                        <span className="font-medium pr-2">Course:</span>{" "}
                        {currentUser?.course}
                      </div>
                      <div className="flex items-center mb-2 flex-wrap">
                        <FaCodeBranch className="mr-3 text-yellow-600" />
                        <span className="font-medium pr-2">
                          Branch Name:
                        </span>{" "}
                        <span className="whitespace-pre-wrap text-sm">
                          {currentUser?.branch}
                        </span>
                      </div>
                      <div className="flex items-center mb-2 flex-wrap">
                        <MdLabel className="mr-3 text-yellow-600" />
                        <span className="font-medium pr-2">Semester:</span>{" "}
                        {currentUser?.semester}
                      </div>
                      <div className="flex items-center flex-wrap">
                        <AiOutlineFieldNumber className="mr-3 text-yellow-600" />
                        <span className="font-medium pr-2">
                          Enrollment Number:
                        </span>{" "}
                        {currentUser?.enrollment_number}
                      </div>
                    </div>
                  </>
                )}

                {isloading === true ? (
                  <>
                    <div className="bg-white shadow-2xl rounded-lg p-4">
                      <Skeleton height={200} />
                    </div>
                  </>
                ) : (
                  <div className="bg-white shadow-xl rounded-lg p-4">
                    <h3 className="text-lg font-semibold mb-4">
                      Internship Details:
                    </h3>
                    <div className="flex items-center mb-2 flex-wrap">
                      <MdTimeline className="mr-3 text-pink-600" />
                      <span className="font-medium pr-2">
                        Internship Type:
                      </span>{" "}
                      {currentUser?.internship_type}
                    </div>
                    <div className="flex items-center mb-2 flex-wrap">
                      <BsPinMapFill className="mr-3 text-pink-600" />
                      <span className="font-medium pr-2">
                        Area Of Internship:
                      </span>{" "}
                      {currentUser?.internship_area}
                    </div>
                    <div className="flex items-center mb-2 flex-wrap">
                      <MdDateRange className="mr-3 text-teal-600" />
                      <span className="font-medium pr-2">Start Date:</span>{" "}
                      {currentUser?.internship_start_date}
                    </div>
                    <div className="flex items-center flex-wrap">
                      <MdDateRange className="mr-3 text-teal-600" />
                      <span className="font-medium pr-2">End Date:</span>{" "}
                      {currentUser?.internship_end_date}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
