import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import * as Yup from "yup";
import FormikInput from "../../../User/Components/Formik/FormikInput";
import FormikSelect from "../../../User/Components/Formik/FormikSelect";
import {
  Branches,
  Courses,
  Internship_Area,
  InternshipType,
  Semesters,
} from "../../../../Data";
import Button from "../../../User/Components/Buttons/Button";
import axios from "axios";
import toast from "react-hot-toast";

function EditUser({ selectedUser, onUpdateClose, fetchUsers }) {
  const [isloading, setIsloading] = useState(false);

  const formikref = useRef();
  const parseDate = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`; // Returns YYYY-MM-DD format
  };
  const handleUpdateUser = (values) => {
    setIsloading(!isloading);
    axios
      .put(`api/v1/users/${selectedUser?.id}`, {
        user: {
          email: values?.email,
          password: values?.password,
          username: values?.username,
          student_name: values?.fullname,
          mobile_number: values?.mobile,
          college_name: values?.college,
          enrollment_number: values?.enrollmentno,
          branch: values?.branch,
          semester: values?.semester,
          course: values?.course,
          internship_type: values?.internship_type,
          internship_start_date: values?.internship_start_date,
          internship_end_date: values?.internship_end_date,
          internship_area: values?.internship_area,
        },
        access_token: localStorage.getItem("access_token"),
      })
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          toast.success(res?.data?.message);
          setIsloading(!isloading);
          fetchUsers();
          onUpdateClose();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsloading(!isloading);
        err?.response?.data?.error.map((e) => toast.error(e));
        onUpdateClose();
      });
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-[#FFFAFA] mx-4  w-full max-w-3xl p-6 rounded-lg shadow-lg relative text-sm">
        <button
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 hover:bg-gray-200 rounded-full p-1"
          onClick={onUpdateClose}
        >
          <FaTimes size={24} />
        </button>
        <div className="mb-6 text-center  rounded-t-xl p-4">
          <h2 className="text-3xl font-semibold text-[#1f1d49]">Update User</h2>
        </div>
        <div className="w-full overflow-auto max-h-[70vh] bg-[#EEEDEB] border border-[#1f1d49] p-4">
          <Formik
            innerRef={formikref}
            initialValues={{
              fullname: selectedUser?.student_name || "",
              email: selectedUser?.email || "",
              mobile: selectedUser?.mobile_number || "",
              college: selectedUser?.college_name || "",
              branch: selectedUser?.branch || "",
              course: selectedUser?.course || "",
              semester: selectedUser?.semester || "",
              enrollmentno: selectedUser?.enrollment_number || "",
              internship_type: selectedUser?.internship_type || "",
              internship_start_date:
                parseDate(selectedUser?.internship_start_date) || "",
              internship_end_date:
                parseDate(selectedUser?.internship_end_date) || "",
              // usename: selectedUser?.username,
              // password: selectedUser?.password,
              internship_area: selectedUser?.internship_area,
            }}
            validationSchema={Yup.object({
              fullname: Yup.string()
                .min(6, "Student name must be 6 characters long")
                .required("* required"),
              email: Yup.string()
                .email("Invalid Email Id")
                .required("* required"),
              mobile: Yup.string()
                .min(10, "Must be 10 digits")
                .max(10, "Must be 10 digits")
                .required("* required"),
              college: Yup.string().required("* required"),
              branch: Yup.string().required("* required"),
              course: Yup.string().required("* required"),
              semester: Yup.string().required("* required"),
              enrollmentno: Yup.string().required("* required"),
              internship_type: Yup.string().required("* required"),
              internship_start_date: Yup.string().required("* required"),
              internship_end_date: Yup.string().required("* required"),
              internship_area: Yup.string().required("* required"),
            })}
            onSubmit={(values) => {
              handleUpdateUser(values);
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <div className="space-y-6">
                  <div>
                    <FormikInput
                      name="fullname"
                      type="text"
                      label={"Full Name"}
                      placeholder="Enter Full Name"
                      onChange={(event) => {
                        setFieldValue("fullname", event.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name="email"
                      type="email"
                      label={"Email"}
                      placeholder="Enter Your Email"
                      onChange={(event) => {
                        setFieldValue("email", event.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name="mobile"
                      type="number"
                      label={"Mobile No."}
                      placeholder="Enter Your Mobile No."
                      onChange={(event) => {
                        setFieldValue("mobile", event.target.value);
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <FormikInput
                        label={"College Name"}
                        name={"college"}
                        onChange={(event) => {
                          setFieldValue("college", event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        label={"Branch Name"}
                        name={"branch"}
                        option={Branches}
                        onChange={(event) => {
                          setFieldValue("branch", event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        label={"Course"}
                        name={"course"}
                        option={Courses}
                        onChange={(event) => {
                          setFieldValue("course", event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        label={"Semester"}
                        name={"semester"}
                        option={Semesters}
                        onChange={(event) => {
                          setFieldValue("semester", event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name="enrollmentno"
                        type="text"
                        label={"Enrollment No."}
                        placeholder="Enter Your Enrollment Number"
                        onChange={(event) => {
                          setFieldValue("enrollmentno", event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div>
                      <FormikSelect
                        label="Type Of Internship"
                        name="internship_type"
                        option={InternshipType}
                        onChange={(event) => {
                          setFieldValue("internship_type", event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        label={"Area Of Internship"}
                        name={"internship_area"}
                        option={Internship_Area}
                        onChange={(event) => {
                          setFieldValue("internship_area", event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Start Date"}
                        name={"internship_start_date"}
                        type={"date"}
                        placeholder={"DD/MM/YY"}
                        onChange={(event) => {
                          setFieldValue(
                            "internship_start_date",
                            event.target.value
                          );
                        }}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"End Date"}
                        name={"internship_end_date"}
                        type={"date"}
                        placeholder={"DD/MM/YY"}
                        onChange={(event) => {
                          setFieldValue(
                            "internship_end_date",
                            event.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="flex items-center justify-center mt-6">
          <Button
            onClick={() => formikref?.current?.submitForm()}
            name={"Update"}
            type={"submit"}
          />
        </div>
      </div>
    </div>
  );
}

export default EditUser;
